<template>
  <b-container>
    <b-row align-h="between" align-v="center">
      <b-col>
        <span class="title">{{ title }}</span>
      </b-col>
      <b-col v-if="showSaveAndCancelButtons" class="d-flex justify-content-end" align-self="center">
        <b-button
          variant="light"
          class="phone-settings-button cancel-button mr-2"
          :class="{ 'phone-settings-buttons-mobile': mobileView}"
          aria-label="Cancel Phone Settings Button"
          @click="cancelFormSubmission"
        >
          Cancel
        </b-button>
        <b-button
          class="phone-settings-button save-button"
          :class="{ 'phone-settings-buttons-mobile': mobileView}"
          aria-label="Update Phone Settings Button"
          @click="handleSubmit"
        >
          <fa-icon v-if="submissionInProgress" icon="spinner" spin />
          <div v-else>
            Save
          </div>
        </b-button>
      </b-col>
    </b-row>

    <b-row v-if="virtualPhones && selectedPhone">
      <b-dropdown
        ref="dropdown"
        left
        variant="outline-primary"
        class="no-border"
      >
        <template #button-content>
          <b>{{ selectedPhone.name }}</b>
          <span v-phone-number="selectedPhone.number" class="ml-2 d-none d-md-inline-flex" />
        </template>

        <b-dropdown-form v-for="(phone) in virtualPhones" :key="phone.id" class="virtual-phone-item" @click="select(phone)">
          <div class="d-flex justify-content-between align-items-center">
            <b>{{ phone.name }}</b>
            <span v-phone-number="phone.number" class="d-none d-md-inline-flex">{{ phone.number }}</span>
          </div>
        </b-dropdown-form>
      </b-dropdown>
    </b-row>
  </b-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { phoneNumber } from '@/directives/directives'

export default {
  name: 'VirtualPhoneMasthead',

  directives: {
    phoneNumber,
  },

  props: {
    title: {
      type: String,
    },
    showSaveAndCancelButtons: {
      type: Boolean,
      default: false,
    },
    mobileView: {
      type: Boolean,
      default: false,
    },
    submissionInProgress: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('virtualPhones', ['virtualPhoneById', 'virtualPhones', 'selectedPhone']),
  },

  async mounted () {
    await this.findOrSetUpVirtualPhoneId()
    this.$emit('loaded')
  },

  methods: {
    ...mapActions('virtualPhones', ['setSelectedPhoneId', 'loadVirtualPhones']),

    select(phone) {
      this.setSelectedPhoneId(phone.id)
      this.$refs.dropdown.hide(true)
      this.$emit('changed')
    },
    async findOrSetUpVirtualPhoneId(){
      let virtualPhoneIdFromURL = this.$route.params.virtualPhoneId
      if(virtualPhoneIdFromURL != undefined){
        await this.setSelectedPhoneId(virtualPhoneIdFromURL)
      }
      if(this.virtualPhones.length === 0){
        await this.loadVirtualPhones()
      }
    },
    async handleSubmit() {
      this.$emit('submit')
    },
    async cancelFormSubmission() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>

.title {
  color: #000000;
  font-weight: 700;
  font-size: 1.75rem;
}

.no-border::v-deep .btn.dropdown-toggle {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  color: #000864;
  font-size: 0.875rem;
  background-color: transparent;
  margin-left: -0.813rem;
}

.no-border::v-deep .btn.dropdown-toggle:hover {
  border: none;
  background-color: transparent;
}

.virtual-phone-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 3.75rem;
  &:not(:first-of-type) {
    border-top: $light-border-color solid 0.063rem;
  }
  &:hover {
    background-color: $ct-ui-color-1;
  }
  form {
    display: flex;
    align-items: center;
    height: 3.75rem;
    > div {
      width: 100%;
    }
  }
}

.phone-settings-button {
  border-radius: 0.2343rem;
  font-size: 0.787rem;
}

.cancel-button {
  color: #4E4E52;
  background-color: #FFFFFF;
}

.save-button {
  background-color: #2E3798;
  color: #FFFFFF
}

.phone-settings-buttons-mobile {
  padding: 0.431rem 0.787rem;
}

</style>
