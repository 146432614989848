var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { attrs: { "align-h": "between", "align-v": "center" } },
        [
          _c("b-col", [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          ]),
          _vm.showSaveAndCancelButtons
            ? _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-end",
                  attrs: { "align-self": "center" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "phone-settings-button cancel-button mr-2",
                      class: {
                        "phone-settings-buttons-mobile": _vm.mobileView,
                      },
                      attrs: {
                        variant: "light",
                        "aria-label": "Cancel Phone Settings Button",
                      },
                      on: { click: _vm.cancelFormSubmission },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "phone-settings-button save-button",
                      class: {
                        "phone-settings-buttons-mobile": _vm.mobileView,
                      },
                      attrs: { "aria-label": "Update Phone Settings Button" },
                      on: { click: _vm.handleSubmit },
                    },
                    [
                      _vm.submissionInProgress
                        ? _c("fa-icon", {
                            attrs: { icon: "spinner", spin: "" },
                          })
                        : _c("div", [_vm._v("\n          Save\n        ")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.virtualPhones && _vm.selectedPhone
        ? _c(
            "b-row",
            [
              _c(
                "b-dropdown",
                {
                  ref: "dropdown",
                  staticClass: "no-border",
                  attrs: { left: "", variant: "outline-primary" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c("b", [_vm._v(_vm._s(_vm.selectedPhone.name))]),
                            _c("span", {
                              directives: [
                                {
                                  name: "phone-number",
                                  rawName: "v-phone-number",
                                  value: _vm.selectedPhone.number,
                                  expression: "selectedPhone.number",
                                },
                              ],
                              staticClass: "ml-2 d-none d-md-inline-flex",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1272842826
                  ),
                },
                _vm._l(_vm.virtualPhones, function (phone) {
                  return _c(
                    "b-dropdown-form",
                    {
                      key: phone.id,
                      staticClass: "virtual-phone-item",
                      on: {
                        click: function ($event) {
                          return _vm.select(phone)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                        },
                        [
                          _c("b", [_vm._v(_vm._s(phone.name))]),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "phone-number",
                                  rawName: "v-phone-number",
                                  value: phone.number,
                                  expression: "phone.number",
                                },
                              ],
                              staticClass: "d-none d-md-inline-flex",
                            },
                            [_vm._v(_vm._s(phone.number))]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }